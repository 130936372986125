import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import MultiPovPage from "./pages/MultiPovPage";
import { Toaster } from "sonner";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import ErrorPage from "./pages/ErrorPage";
import moment from "moment";
import "moment/locale/fr";
import StreamersPage from "./pages/StreamersPage";

moment().locale("fr");

const router = createBrowserRouter([
  {
    path: "/",
    element: <StreamersPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/multipov",
    element: <MultiPovPage />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/clips",
  //   element: <ClipsPage />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/vods",
  //   element: <VodsPage />,
  //   errorElement: <ErrorPage />,
  // },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Toaster theme="dark" richColors expand />
    <RouterProvider router={router} />
    <Analytics />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
