import React from "react";
import styled, { keyframes } from "styled-components";
import SmallMadzLogo from "../assets/logo.png";
export default function Loader() {
  return (
    <Container>
      <SmallLogo src={SmallMadzLogo} alt="Madz Logo" />
    </Container>
  );
}
const heartBeat = keyframes`
  0%
  {
    opacity: 1;
    scale: 1;
  }
  50%
  {
    opacity: 0.5;
    scale: 0.75;
  }
  100%
  {
    opacity: 1;
    scale: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const SmallLogo = styled.img`
  height: 70px;
  animation: ${heartBeat} 2s infinite;
`;
