import styled from "styled-components";
import Loader from "../components/Loader";
import Streamer from "../components/StreamersList/Streamer";

function StreamersList(props) {
  const { streamerList, viewStream } = props;

  const onlineStreamers = streamerList
    .filter((streamer) => streamer.online)
    .sort((a, b) => b.viewer_count - a.viewer_count);
  const offlineStreamers = streamerList.filter((streamer) => !streamer.online);
  return (
    <>
      <Container>
        {streamerList.length === 0 ? (
          <Loader />
        ) : (
          <>
            <BigTitle>
              <RedLetter>S</RedLetter>treamers en ligne (
              {onlineStreamers.length})
            </BigTitle>
            <StreamerList>
              {onlineStreamers.map((streamer) => (
                <Streamer streamer={streamer} viewStream={viewStream} />
              ))}
            </StreamerList>
            <BigTitle>
              <RedLetter>S</RedLetter>treamers hors ligne (
              {offlineStreamers.length})
            </BigTitle>
            <OfflineStreamerList>
              {offlineStreamers.map((streamer) => (
                <Streamer streamer={streamer} viewStream={viewStream} />
              ))}
            </OfflineStreamerList>
          </>
        )}
      </Container>
    </>
  );
}

const BigTitle = styled.h1`
  font-weight: 200;
  margin: 0;
`;

const RedLetter = styled.span`
  color: #c22f1b;
  font-weight: 600;
`;

const Container = styled.div`
  min-height: 40vh;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  // make it round
  border-radius: 6px;
  // make the background a bit transparent
  background: rgba(32, 35, 42, 0.98);
  position: relative;
`;

const StreamerList = styled.div`
  display: grid;
  // Exacly 320px width for each Streamer
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 16px;
  margin-top: 40px;
  margin-bottom: 60px;
`;

const OfflineStreamerList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 32px;
  margin-top: 40px;
  margin-bottom: 60px;
`;

export default StreamersList;
