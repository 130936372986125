import styled from "styled-components";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import Header from "../components/Header";
import StreamersList from "../components/StreamersList";
import EmptyStateImg from "../assets/empty-state.jpg";

function StreamersPage() {
  const [streamerList, setStreamerList] = useState([]);

  // Fetch streamers
  const fetchStreamers = async (isInit) => {
    try {
      const response = await fetch("https://panel.utopia-rp.fr/api/streamer");
      const data = await response.json();
      if (!data.success)
        return toast.error(
          "Une erreur est survenue lors de la récupération des streamers"
        );

      const streamers = data.streamers;
      setStreamerList(streamers);
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch streamers on init
  useEffect(() => {
    fetchStreamers(true);
  }, []);

  // Refetch streamers every 5 minutes
  useEffect(() => {
    setInterval(() => {
      fetchStreamers(false);
    }, 300000);
  }, []);

  return (
    <div>
      <Header></Header>
      <LeftPaneImage backgroundUrl={EmptyStateImg} />
      <Container>
        <StreamersList
          streamerList={streamerList}
          viewStream={(streamerName) => {
            window.location.href = `/multipov?selectedChannels=${streamerName}`;
          }}
        />
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 160px);
  width: calc(100% - 80px);
  padding: 40px;
`;

const LeftPaneImage = styled.div`
  width: 100%;
  min-height: 100%;
  opacity: 0.2;
  position: absolute;
  background-image: url(${(props) => props.backgroundUrl});
  background: linear-gradient(rgba(240, 100, 13, 0.3), rgba(32, 32, 32, 1)),
    url(${(props) => props.backgroundUrl});

  background-size: cover;
  background-position: center;
  top: 0;
  left: 0;
  z-index: -1;
`;
export default StreamersPage;
