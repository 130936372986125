import styled from "styled-components";
import MadzLogo from "../assets/logo.png";
import { Link } from "react-router-dom";

function Header(props) {
  return (
    <Container>
      <LeftMenu>
        <h1>
          <Link to="/">
            <img src={MadzLogo} alt="Madz Logo" style={{ height: "40px" }} />
          </Link>
        </h1>
        <CustomMenuItem to="/">Lives</CustomMenuItem>
        <CustomMenuItem to="/multipov">MultiPov</CustomMenuItem>
        {/* <CustomMenuItem to="/clips">Clips</CustomMenuItem>
        <CustomMenuItem to="/vods">VODs</CustomMenuItem> */}
      </LeftMenu>
      {props.children}
    </Container>
  );
}

const LeftMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CustomMenuItem = styled(Link)`
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  color: "white";
  &:hover {
    color: grey;
  }
`;

const Container = styled.div`
  height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  h1 {
    margin: 0;
    padding: 0;
  }
  border-bottom: 1px solid #20232a;
  background-color: #282c34;
`;

export default Header;
