import { useState } from "react";
import styled from "styled-components";

function Streamer(props) {
  const { streamer, viewStream } = props;
  const [isHover, setIsHover] = useState(false);
  const isOnline = streamer.online;
  return (
    <StreamerContainer>
      {isOnline ? (
        <>
          <StreamerThumbnail
            backgroundUrl={streamer.thumbnail_url}
            onClick={() => viewStream(streamer.name)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            {isHover && (
              <HoverInfoContainer>See in the MultiPov</HoverInfoContainer>
            )}
            <StreamerViewCount>
              {streamer.viewer_count} viewers
            </StreamerViewCount>
          </StreamerThumbnail>
          <StreamerInformation
            href={`https://www.twitch.tv/${streamer.name}`}
            target="_blank"
            rel="noreferrer"
          >
            <TwitchChannelImage src={streamer.profile_image_url} />
            <StreamerSubInformation>
              <StreamerName>{streamer.display_name}</StreamerName>
              <StreamerStreamTitle>{streamer.title}</StreamerStreamTitle>
            </StreamerSubInformation>
          </StreamerInformation>
        </>
      ) : (
        <OfflineInformations
          href={`https://www.twitch.tv/${streamer.name}`}
          target="_blank"
          rel="noreferrer"
        >
          <OfflineChannelImage src={streamer.profile_image_url} />
          <OfflineStreamerInformation>
            {streamer.display_name}
          </OfflineStreamerInformation>
        </OfflineInformations>
      )}
    </StreamerContainer>
  );
}

const HoverInfoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StreamerContainer = styled.a`
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
`;

const StreamerThumbnail = styled.div`
  width: 320px;
  height: 180px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

const TwitchChannelImage = styled.img`
  margin-right: 5px;
  border-radius: 25%;

  width: 40px;
  height: 40px;
`;

const OfflineChannelImage = styled.img`
  margin-right: 5px;
  border-radius: 25%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  width: 100%;
  height: 100%;
`;

const OfflineInformations = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-color: #20232a;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  color: inherit;
`;
const OfflineStreamerInformation = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  color: inherit;
  text-decoration: none;
  &:hover {
    color: grey;
    opacity: 0.7;
  }
  cursor: pointer;
`;

const StreamerInformation = styled.a`
  display: flex;
  flex-direction: row;
  width: 320px;
  margin-top: 5px;
  color: inherit;
  text-decoration: none;
  &:hover {
    color: grey;
    opacity: 0.7;
  }
  cursor: pointer;
`;
const StreamerSubInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const StreamerName = styled.a`
  font-size: 16px;
`;

const StreamerStreamTitle = styled.div`
  font-size: 0.7rem;
  color: #a6a6a6;
`;

const StreamerViewCount = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: #20232a;
  color: white;
  padding: 2px;
  border-radius: 5px;
  font-size: 0.7rem;
`;
export default Streamer;
